import React from "react";
import { isMobile } from "react-device-detect";

// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";
import about from "../assets/images/about.png";
import map from "../assets/images/map.png";
import Footer from "../elements/Footer";
import Navbar from "../elements/Navbar";

import "../stylesheets/about.css";

function AboutUs() {
  return (
    <React.Fragment>
      <Navbar />
      <div className="about-container">
        <img src={about} width={window.innerWidth} alt="" />
        <div className="myabout">
          <div class="about-text">
            On Africanmovies.com, stream African movies, on any of your devices.
          </div>
          {!isMobile && (
            <div class="about-small">
              Enjoy the best of African movies from Anywhere.
            </div>
          )}
        </div>
      </div>
      <div>
        {/* <ZuluLogo class="about-logo" /> */} African Movies
        <h4 class="about-perspective">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </h4>
      </div>
      <div className="about-container">
        <h6>Our Locations</h6>
        <img src={map} width={window.innerWidth} alt="" />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default AboutUs;

// import React from "react";
// import { isMobile } from "react-device-detect";
// import { IoCall } from "react-icons/io5";
// import { FaLocationDot } from "react-icons/fa6";
// import { IoMdMail } from "react-icons/io";
// // import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";
// import about from "../assets/images/about.png";
// import map from "../assets/images/map.png";
// import Footer from "../elements/Footer";
// import Navbar from "../elements/Navbar";

// import "../stylesheets/about.css";

// function AboutUs() {
//   return (
//     <React.Fragment>
//       <Navbar />
//       <div className="about-container">
//         <img src={about} width={window.innerWidth} alt="About Us" />
//         <div className="overlay"></div>
//         <div className="myabout">
//           <div className="about-text">
//             Discover the best of African cinema, streaming anytime, anywhere.
//           </div>
//           {!isMobile && (
//             <div className="about-small">
//               Experience authentic African stories from the comfort of your
//               home.
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="about-section">
//         <h2 className="about-logo">Sanga Entertainment</h2>
//         <h4 className="about-perspective">
//           Welcome to sanga entertainment – your gateway to the heart of African
//           storytelling! Immerse yourself in a diverse world of drama, romance,
//           action, and comedy, curated from the finest filmmakers across the
//           continent. We bring you an unrivaled streaming experience, delivering
//           high-quality African entertainment at your fingertips.
//         </h4>
//       </div>
//       <div className="company-details">
//         <div className="contact-item">
//           <IoCall size={50} color="#00a5c3" />
//           <br />
//           <br />
//           <br />
//           <h3>Call Us</h3>
//           <p>+1 (332) 262-8434</p>
//           {/* <p>+1 (917) 406-9085</p> */}
//         </div>
//         <div className="contact-item">
//           <FaLocationDot size={50} color="#00a5c3" />
//           <br />
//           <br />
//           <br />
//           <h3>Visit Us</h3>
//           <p>
//             14th Wall Street 20th floor
//             <br /> New York, NY 10005
//           </p>
//           <p>New York, United States</p>
//         </div>
//         <div className="contact-item">
//           <IoMdMail size={50} color="#00a5c3" />
//           <br />
//           <br />
//           <br />
//           <h3>Email Us</h3>
//           <p>movies@sangaentertainment.com</p>
//           <p>info@sangaentertainment.com</p>
//         </div>
//       </div>
//       <div className="about-container">
//         <h6>Our Locations</h6>
//         <img src={map} width={window.innerWidth} alt="Locations" />
//       </div>
//       <Footer />
//     </React.Fragment>
//     // <React.Fragment>
//     //   <Navbar />
//     //   <div className="about-container">
//     //     <img src={about} width={window.innerWidth} alt="" />
//     //     <div className="myabout">
//     //       <div class="about-text">
//     //         On Africanmovies.com, stream African movies, on any of your devices.
//     //       </div>
//     //       {!isMobile && (
//     //         <div class="about-small">
//     //           Enjoy the best of African movies from Anywhere.
//     //         </div>
//     //       )}
//     //     </div>
//     //   </div>
//     //   <div>
//     //     {/* <ZuluLogo class="about-logo" /> */} African Movies
//     //     <h4 class="about-perspective">
//     //       Lorem Ipsum is simply dummy text of the printing and typesetting
//     //       industry. Lorem Ipsum has been the industry's standard dummy text ever
//     //       since the 1500s, when an unknown printer took a galley of type and
//     //       scrambled it to make a type specimen book. It has survived not only
//     //       five centuries, but also the leap into electronic typesetting,
//     //       remaining essentially unchanged. It was popularised in the 1960s with
//     //       the release of Letraset sheets containing Lorem Ipsum passages, and
//     //       more recently with desktop publishing software like Aldus PageMaker
//     //       including versions of Lorem Ipsum.
//     //     </h4>
//     //   </div>
//     //   <div className="about-container">
//     //     <h6>Our Locations</h6>
//     //     <img src={map} width={window.innerWidth} alt="" />
//     //   </div>
//     //   <Footer />
//     // </React.Fragment>
//   );
// }

// export default AboutUs;
