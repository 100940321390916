import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";
import { fetchUsersAsync } from "../redux/users/users.action";
import {
  fetchFavMoviesAsync,
  fetchWatchlistMoviesAsync,
  fetchMoviesAsync,
  fetchLongevityAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";

import { getCurrentUser as user } from "../services/authServices";
import MobileMovieDetailsBanner from "../elements/MMovieDetailsBanner";
import MovieDetailsBanner from "../elements/MovieDetailsBanner";
import Navbar from "../elements/Navbar";
import MovieDetailsFavourite from "../elements/MovieDetailsFavorite";
import MovieOverview from "../elements/MovieOverview";
import MoviesMightLike from "../elements/MoviesMightLike";
import Footer from "../elements/Footer";
import Loader from "../elements/Loader";

function MovieDetails({
  allMovies,
  fetchFavMoviesAsync,
  fetchMoviesAsync,
  fetchUsersAsync,
  fetchOrderAsync,
  fetchLongevityAsync,
  fetchWatchlistMoviesAsync,
}) {
  //const navigate = useNavigate();
  const { id } = useParams();
  const currentUser = user();
  const movie = allMovies.find((m) => m._id === id);
  const mightLikeMovies = allMovies.filter((m) => m._id !== id);

  // useEffect(() => {
  //   const handlePopState = () => {
  //     // redirect to landing page on browser back
  //     navigate("/", { replace: true });
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  useEffect(() => {
    fetchMoviesAsync();
    fetchFavMoviesAsync();
    fetchUsersAsync();
    fetchLongevityAsync();
    fetchWatchlistMoviesAsync();
    if (currentUser) fetchOrderAsync();
  }, [
    fetchFavMoviesAsync,
    fetchMoviesAsync,
    fetchWatchlistMoviesAsync,
    fetchUsersAsync,
    fetchOrderAsync,
  ]);

  return (
    <>
      {movie ? (
        <>
          <Navbar />
          <MovieDetailsBanner movie={movie} />
          <MobileMovieDetailsBanner movie={movie} />
          <MovieDetailsFavourite movie={movie} />
          <MovieOverview movie={movie} />
          <MoviesMightLike
            genre={movie.genre}
            movies={mightLikeMovies}
            actors={movie.actor}
          />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchFavMoviesAsync: () => dispatch(fetchFavMoviesAsync()),
  fetchWatchlistMoviesAsync: () => dispatch(fetchWatchlistMoviesAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchUsersAsync: () => dispatch(fetchUsersAsync()),
  fetchLongevityAsync: () => dispatch(fetchLongevityAsync()),
});

const mapStateToProps = createStructuredSelector({
  allMovies: selectVertualMovies,
});

export default connect(mapStateToProps, mapDispatchToProps)(MovieDetails);
