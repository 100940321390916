import React from "react";
import TawkTo from "tawkto-react";
import Android from "../assets/images/android.png";
import Apple from "../assets/images/apple.png";
import { IoIosCard } from "react-icons/io";
import Logo from "../assets/images/logo_test.png";

// import { IoMdCall, IoMdMail } from "react-icons/io";

import { Link } from "react-router-dom";
// import { ReactComponent as ZuluLogo } from "../assets/images/svg/logo.svg";

function Footer() {
  const startChat = () => {
    var tawk = new TawkTo("62377aba1ffac05b1d7f851f", "1fukbeqts");
    tawk.onStatusChange((status) => {
      alert(
        "A chat box will show up on the bottom right corner, kindly click to chat with our support team."
      );
    });
  };

  return (
    <footer className="text-center text-lg-start bg-dark text-primary pt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          {/* <!-- Section: Links  --> */}
          <section className="">
            <div className="container text-center text-md-start mt-5">
              {/* <!-- Grid row --> */}
              <div className="row mt-3">
                {/* <!-- Grid column --> */}
                <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4">
                  {/* <!-- Content --> */}
                  <h6 className="text-uppercase fw-bold mb-2">
                    {/* <ZuluLogo className="logo" alt="" /> */}
                    <img src={Logo} alt="default" width={150} height={40} />
                  </h6>
                  <p className="text-muted">
                    African Movies, is a platform on which you can stream your
                    favorites african movies on any of your devices.
                    {/* Sanga Entertainment, discover the best African films and
                    original productions. Watch, create, and celebrate authentic
                    African storytelling—all in one place. */}
                  </p>
                </div>
                {/* <!-- Grid column -->

              <!-- Grid column --> */}
                {/* <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  
                  <h6 className="text-uppercase fw-bold mb-2 text-muted">
                    trending movies
                  </h6>
                  <p>
                    <Link to="#!" className="text-reset">
                      Haathi Mere Saathi
                    </Link>
                  </p>
                  <p>
                    <Link to="#!" className="text-reset">
                      Cocktail
                    </Link>
                  </p>
                  <p>
                    <Link to="#!" className="text-reset">
                      Sarkar 3
                    </Link>
                  </p>
                  <p>
                    <Link to="#!" className="text-reset">
                      Sanam Teri Kasam
                    </Link>
                  </p>
                  <p>
                    <Link to="#!" className="text-reset">
                      Watch Movies Online
                    </Link>
                  </p>
                </div> */}

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h6 className="text-uppercase fw-bold mb-2 text-muted">
                    Get Our App
                  </h6>
                  <div>
                    <img
                      src={Android}
                      alt="Android logo"
                      onClick={() => (window.location = "/")}
                      width={120}
                      height={50}
                      style={{
                        margin: "auto",
                        display: "block",
                        cursor: "pointer",
                      }}
                    />
                    {/* <br /> */}
                    <img
                      src={Apple}
                      alt="Android logo"
                      onClick={() => (window.location = "/")}
                      width={180}
                      height={70}
                      style={{
                        margin: "auto",
                        display: "block",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  {/* <!-- Links --> */}
                  <h6 className="text-uppercase fw-bold mb-2 text-muted">
                    Features
                  </h6>
                  <p>
                    <span className="text-reset">
                      Easy Payment <IoIosCard />
                    </span>
                  </p>
                  <p>
                    <span className="text-reset">Devices</span>
                  </p>
                  <p>
                    <span className="text-reset">African Movies</span>
                    {/* <span className="text-reset">Sanga Entertainment</span> */}
                  </p>
                  <p>
                    <span className="text-reset">Stream</span>
                  </p>
                </div>
                {/* <!-- Grid column -->

              <!-- Grid column --> */}
                <div className="col-md-2 col-lg-2 col-xl-3 mx-auto mb-md-0 mb-4">
                  {/* <!-- Links --> */}
                  <h6 className="text-uppercase fw-bold mb-2 text-muted">
                    Company
                  </h6>
                  {/* <p className="text-reset">
                    14 Wall Street 20th floor <br /> New York, NY 10005
                  </p>
                  <p>
                    <IoMdCall /> +1 (917) 406-9085
                  </p>
                  <p>
                    <IoMdMail /> movies@sangaentertainment.com
                  </p> */}
                  <p>
                    <Link to="/aboutus" className="text-reset">
                      About Us
                    </Link>
                  </p>
                  <p>
                    <Link to="/helpcenter" className="text-reset">
                      Help Center
                    </Link>
                  </p>
                  <p>
                    <span
                      onClick={startChat}
                      className="text-reset"
                      style={{ cursor: "pointer" }}
                    >
                      Contact Us
                    </span>
                  </p>
                </div>
                {/* <!-- Grid column --> */}
              </div>
              {/* <!-- Grid row --> */}
            </div>
          </section>
          {/* <!-- Section: Links  -->

        <!-- Section: Social media --> */}
          <section
            className="
            d-flex
            justify-content-center justify-content-lg-center
            text-muted
          "
          >
            {/* <!-- Left --> */}
            <div className="me-5 d-none text-uppercase fw-bold d-lg-block">
              <span>connect with us</span>
            </div>
            {/* <!-- Left -->

          <!-- Right --> */}
            <div>
              <Link to="" className="me-4 text-reset">
                <i className="fab fa-facebook-f fa-lg"></i>
              </Link>
              <Link to="" className="me-4 text-reset">
                <i className="fab fa-twitter fa-lg"></i>
              </Link>
              <Link to="" className="me-4 text-reset">
                <i className="fab fa-instagram fa-lg"></i>
              </Link>
              <Link to="" className="text-reset">
                <i className="fab fa-linkedin fa-lg"></i>
              </Link>
            </div>
            {/* <!-- Right --> */}
          </section>
          {/* <!-- Section: Social media -->

        <!-- Copyright --> */}
          <div className="text-center py-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <p>
                  <Link to="#!" className="text-reset">
                    Terms & Conditions
                  </Link>
                </p>
              </div>
              <div className="col-12 col-md-2">
                <p>
                  <Link to="#!" className="text-reset">
                    Privacy Policy
                  </Link>
                </p>
              </div>
              <div className="col-12 col-md-2">
                <p>
                  <Link to="/helpcenter" className="text-reset">
                    Help Center
                  </Link>
                </p>
              </div>
              <div className="col-12 col-md-5 text-muted">
                © {new Date().getFullYear()} African Movies Company Ltd.
                {/* © {new Date().getFullYear()} Sanga Entertainment Company Ltd. */}
                <span className="fw-bold">All rights reserved.</span>
              </div>
            </div>
          </div>
          {/* <!-- Copyright --> */}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
